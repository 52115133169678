import { FC } from 'react';
import Link from 'next/link';
import LitresWidget from '../litres-widget/LitresWidget';
import BookCardImg from '../book-card-img/BookCardImg';
import BtnReadOnline from '../btn-read-online/BtnReadOnline';
import { getAuthorFullName } from '@/helpers/helpers';
import { parseISO, format } from 'date-fns';
import { getTruncatedTxt } from '@/helpers/helpers';
import { IBook, IAuthor, IGenre } from '@/interfaces/home.interface';
import styles from './BookCardItem.module.scss';

interface IBookCardItem {
  book: IBook;
  author: IAuthor;
  rating: number;
  id: number;
  comments?: number;
  createdAt?: string;
  hideLitresData?: boolean;
  isPodcast?: boolean;
}

const BookCardItem: FC<IBookCardItem> = ({ id, book, author, rating, comments, createdAt, hideLitresData, isPodcast }) => {
  const { title, type, description, genres } = book;
  const { first_name, middle_name, last_name } = author;
  const authorFullName = getAuthorFullName(first_name, middle_name, last_name);

  const bookFullAlias = !!isPodcast ? `/lib/podcasts/id${id}` : `/lib/id${id}`;
  const authorFullAlias = `/author/id${author.id}`;

  //Stars render
  const getBookCardRatingStars = (rating: number | undefined) => {
    const bookRating = Number(rating);

    if (bookRating > 0 && bookRating < 6) {
      const greyStars = 5 - bookRating;
      return (
        <>
          {Array.from(Array(bookRating), () => {
            return <span className={`${styles.book_card_star} ${styles.book_card_starActive}`} key={`${Math.random()}-orange`}></span>;
          })}
          {Array.from(Array(greyStars), () => {
            return <span className={`${styles.book_card_star}`} key={`${Math.random()}-gray`}></span>;
          })}
          <span className={styles.book_card_star_rate}>{bookRating}</span>
        </>
      );
    } else {
      return (
        <>
          {Array.from(Array(5), () => {
            return <span className={`${styles.book_card_star}`} key={`${Math.random()}-gray`}></span>;
          })}
          <span className={styles.book_card_star_rate}>{bookRating}</span>
        </>
      );
    }
  };

  return (
    <div className={styles.item} data-book-item>
      <div className={styles.book_name}>
        <Link prefetch={false} href={bookFullAlias}>
          {!!authorFullName ? `${authorFullName} - ${title}` : `${title}`}
        </Link>
      </div>
      <div className={styles.book_list_desc}>
        <div className={styles.img}>
          <BookCardImg bookFullAlias={bookFullAlias} coverId={book.external_id} title={title} bookId={id} />
          <div className={styles.book_card_rating}>{getBookCardRatingStars(rating)}</div>
        </div>
        <div className={styles.desc}>
          <div className={styles.book_info}>
            {!!authorFullName && (
              <div className={styles.row}>
                <span className={styles.name}>Автор: </span>
                <span>
                  <Link prefetch={false} className={styles.name_link} href={authorFullAlias} data-widget-litres-author>
                    {authorFullName}
                  </Link>
                </span>
              </div>
            )}

            <div className={styles.row}>
              <span className={styles.name}>Название: </span>
              <Link prefetch={false} className={styles.name_link} href={bookFullAlias} data-widget-litres-book>
                {title}
              </Link>
            </div>

            {genres.length > 0 && (
              <div className={styles.row}>
                <span className={styles.name}>Жанр: </span>

                {genres.map((genre: IGenre, i: number) => (
                  <span key={genre.id}>
                    <Link prefetch={false} className={styles.name_link} href={`/genre/${genre.alias}`}>
                      {genre.title}
                    </Link>
                    {i < genres.length - 1 ? ', ' : ''}
                  </span>
                ))}
              </div>
            )}

            <div className={`${styles.book_card_rating} ${styles.book_card_ratingMob}`}>{getBookCardRatingStars(rating)}</div>
          </div>

          <div className={styles.annotation}>
            {description.length > 1 && (
              <>
                <div className={styles.annotation_title}>Аннотация на книгу:</div>
                <div className={styles.txt}>
                  <p>{getTruncatedTxt(description, 400)}</p>
                </div>
              </>
            )}
          </div>

          {!hideLitresData && (
            <div className={styles.sale_block}>
              <div data-litres-widget className={styles.litres_widgets}>
                <LitresWidget bookTitle={title} lfrom={516367889} />
              </div>
            </div>
          )}

          {type === 'book' && (
            <div className={styles.wrap_btn}>
              <BtnReadOnline alias={`/lib/id${id}/read`} />
            </div>
          )}
        </div>
      </div>

      <div className={styles.b_biblio_book_line}>
        <span className={`${styles.added} ${styles.bolder_r}`}>
          <span className={styles.posted}>
            {createdAt && (
              <>
                Добавленo: <time dateTime={createdAt}>{format(parseISO(createdAt!), 'dd.MM.yyyy')}</time> <time dateTime={createdAt}>{format(parseISO(createdAt!), 'HH:MM')}</time>
              </>
            )}
          </span>
        </span>

        <span className={styles.rating} id="rating_{{ book.id }}">
          Рейтинг: {rating > 0 ? rating : 'Будь первым кто оценит книгу'}{' '}
        </span>

        <span className={styles.comments}>
          <Link prefetch={false} href="#comments">
            Комментариев {!!comments ? comments : '0'} шт.
          </Link>
        </span>
      </div>
    </div>
  );
};

export default BookCardItem;
